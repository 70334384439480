import React from 'react'
import BannerWrapped from 'blocks/BannerWrapped/template'
import CollectionPostBuilder from 'blocks/CollectionPostBuilder/template'
import ProductGrid from 'blocks/ProductGrid/template'
import Pagination from 'components/Pagination'
import * as styles from './styles.module.scss'
import { getPaginationItems } from 'js/utils'

const ContentType = ({ content, locale }) => {
  if (!locale) locale = 'en'
  const { collection, collections, collectionProducts } = content
  const { title, description, images, collectionPostBuilder } = collection
  const paginationItems = getPaginationItems(collection, collections, locale)

  const strings = {
    en: {
      allProducts: 'All Products',
      collections: 'Collections',
      collection: 'Collection',
    },
    zh: {
      allProducts: '所有产品',
      collections: '系列',
      collection: '系列',
    },
  }
  const tr = strings[locale]

  const blockContent = {
    bannerWrapped: {
      // pretitle: collection.productDiv?.data.frontmatter[locale].titleShorter,
      // pretitleLink: `/${collection.productDiv.slug}`,
      pretitle: tr.collections,
      pretitleLink: '/collections',
      title,
      image: images?.main,
      body: description,
    },
    productGrid: {
      title: tr.allProducts,
      products: collectionProducts,
    },
    pagination: {
      pretitle: tr.collection,
      prev: {
        pageLink: `/collections/${paginationItems.prevItem.frontmatter[locale].slug}`,
        title: paginationItems.prevItem.frontmatter[locale].title,
      },
      next: {
        pageLink: `/collections/${paginationItems.nextItem.frontmatter[locale].slug}`,
        title: paginationItems.nextItem.frontmatter[locale].title,
      },
    },
  }
  const bgClass = collection.productDiv?.slug === 'teaware' ? 'bg-white' : ''

  return (
    <div className={`${styles.page} ${bgClass}`}>
      <BannerWrapped content={blockContent.bannerWrapped} />
      <CollectionPostBuilder content={collectionPostBuilder} />
      <ProductGrid content={blockContent.productGrid} locale={locale} />
      <Pagination content={blockContent.pagination} locale={locale} />
    </div>
  )
}

export default ContentType
