import React from 'react'
import TextImage from 'blocks/TextImage/template'
import TextImageVertical from 'blocks/TextImageVertical/template'

const Block = ({ content, locale }) => {
  const components = {
    textImage: TextImage,
    textImageVertical: TextImageVertical,
  }

  return (
    <>
      {content?.map((block, i) => {
        if (components[block.type]) {
          return React.createElement(components[block.type], {
            content: block,
            locale,
            key: i,
          })
        }
      })}
    </>
  )
}

export default Block
