import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/Seo'
import PageTemplate from './template'

const ContentType = (props) => {
  const { data, pageContext, layoutProps } = props

  const { locale } = pageContext

  const productDiv = data.productDiv
  const addProductDivData = {
    slug: productDiv.frontmatter.en.slug,
    data: productDiv,
  }

  let collection = data.collection
  collection.frontmatter.en.productDiv = addProductDivData
  collection.frontmatter.zh.productDiv = addProductDivData

  const collections = data.collections.nodes

  let collectionProducts = data.collectionProducts.nodes
  collectionProducts.forEach((item) => {
    const addCollectionData = {
      slug: collection.frontmatter.en.slug,
      data: collection,
    }
    item.frontmatter.en.collection = addCollectionData
    item.frontmatter.zh.collection = addCollectionData
  })

  const collectionLocale = collection.frontmatter[locale]
  const strings = {
    en: {
      collections: 'Collections',
    },
    zh: {
      collections: '系列',
    },
  }
  const tr = strings[locale]
  const content = {
    collection: collectionLocale,
    collections,
    collectionProducts,
    title: `${tr.collections} - ${collectionLocale.title}`,
    seo: collectionLocale.seo,
  }

  return (
    <>
      <Seo content={content} />
      <PageTemplate
        content={content}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

export default ContentType

export const pageQuery = graphql`
  query ($productDivSlug: String, $collectionSlug: String) {
    productDiv: markdownRemark(
      frontmatter: {
        en: { slug: { eq: $productDivSlug }, templateKey: { eq: "productDiv" } }
      }
    ) {
      frontmatter {
        en {
          slug
          title
        }
        zh {
          slug
          title
        }
      }
    }
    collection: markdownRemark(
      frontmatter: {
        en: { slug: { eq: $collectionSlug }, templateKey: { eq: "collection" } }
      }
    ) {
      frontmatter {
        en {
          slug
          title
          productDiv
          images {
            main {
              childImageSharp {
                gatsbyImageData
              }
            }
            secondary {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          description
          collectionPostBuilder {
            type
            title
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            body
          }
        }
        zh {
          slug
          title
          productDiv
          images {
            main {
              childImageSharp {
                gatsbyImageData
              }
            }
            secondary {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          description
          collectionPostBuilder {
            type
            title
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            body
          }
        }
      }
    }
    collections: allMarkdownRemark(
      filter: { frontmatter: { en: { templateKey: { eq: "collection" } } } }
      sort: { fields: frontmatter___en___order }
    ) {
      nodes {
        frontmatter {
          en {
            order
            title
            slug
          }
          zh {
            order
            title
            slug
          }
        }
      }
    }
    collectionProducts: allMarkdownRemark(
      filter: {
        frontmatter: {
          en: {
            templateKey: { eq: "product" }
            collection: { eq: $collectionSlug }
          }
        }
      }
    ) {
      nodes {
        frontmatter {
          en {
            title
            slug
            collection
            productCat
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          zh {
            title
            slug
            collection
            productCat
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
