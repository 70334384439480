import React from 'react'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
import { getProductDiv } from 'js/utils'

const Component = ({
  products,
  collections,
  productDivSlug,
  locale,
  columns,
}) => {
  let columnClass = ''
  if (columns === 4) columnClass = styles.columns4

  return (
    <div className={`${styles.grid} grid-12 ${columnClass}`}>
      {products?.map((item, i) => {
        return (
          <Product
            content={item}
            collections={collections}
            productDivSlug={productDivSlug}
            locale={locale}
            key={i}
            i={i}
          />
        )
      })}
    </div>
  )
}

export default Component

const Product = ({
  content,
  collections,
  locale,
  i,
  columns,
  productDivSlug,
}) => {
  const { slug, title, image } = content || {}

  if (!productDivSlug) {
    productDivSlug = getProductDiv(content, locale).slug
  }
  const link = `/${productDivSlug}/${slug}`
  let thisCollectionTitle
  if (collections) {
    // from ProductSection
    const thisCollection = collections.find(
      (collection) =>
        collection.slug === content.collection ||
        collection.slug === content.collection.slug
    )
    thisCollectionTitle = thisCollection?.title
  } else {
    // from ProductGrid, ProductGridSelect
    thisCollectionTitle = content.collection?.data.frontmatter[locale].title
  }
  let columnClass = ''
  if (columns === 4) columnClass = styles.columns4

  return (
    <Inview className={`${styles.product} ${columnClass}`}>
      <div
        className={`${styles.border} ${styles.vertical} fade-in up stagger-${
          i * 50 + 500
        }`}
      />
      <div
        className={`${styles.border} ${styles.horizontal} fade-in up stagger-${
          i * 50 + 500
        }`}
      />
      <div className={`fade-in up stagger-${i * 50 + 500}`}>
        <LinkWrap to={link}>
          <ImageWrap image={image} aspectRatio={4 / 3} />
          <div className={styles.text}>
            <h6>{thisCollectionTitle}</h6>
            <h4 className='smaller'>{title}</h4>
          </div>
        </LinkWrap>
      </div>
    </Inview>
  )
}
