import React from 'react'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { title, body, image } = content || {}
  return (
    <Inview className='pad-v-standard fade-in up'>
      <div className={`container grid-12 ${styles.grid}`}>
        <div className={styles.text}>
          <h2>{title}</h2>
          <MarkdownWrap body={body} />
        </div>
        <div className={styles.image}>
          <ImageWrap image={image} />
        </div>
      </div>
    </Inview>
  )
}

export default Block
