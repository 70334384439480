import React from 'react'
import ProductGrid from 'components/ProductGrid'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, locale }) => {
  const { title, products } = content
  const productsLocale = products.map((product) => product.frontmatter[locale])

  return (
    <div className={`${styles.component} pad-v-standard`}>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          <h3>{title}</h3>
        </Inview>
        <ProductGrid products={productsLocale} locale={locale} columns={4} />
      </div>
    </div>
  )
}

export default Block
