import React from 'react'
import LineLink from 'components/LineLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ content, locale }) => {
  const { pretitle, prev, next } = content
  const strings = {
    en: {
      prev: 'Previous ',
      next: 'Next ',
    },
    zh: {
      prev: '上一个',
      next: '下一个',
    },
  }
  const tr = strings[locale]
  return (
    <div className='container'>
      <Inview className={`fade-in up ${styles.inner}`}>
        <PaginationItem
          pretitle={pretitle}
          content={prev}
          direction='prev'
          tr={tr}
        />
        <PaginationItem
          pretitle={pretitle}
          content={next}
          direction='next'
          tr={tr}
        />
      </Inview>
    </div>
  )
}

export default Component

const PaginationItem = ({ content, pretitle, direction, tr }) => {
  const { title, pageLink } = content
  const addClass = direction === 'prev' ? styles.prev : styles.next
  return (
    <div className={addClass}>
      {pretitle && direction === 'prev' && (
        <h6>
          « {tr.prev}
          <span className={styles.pretitle}>{pretitle}</span>
        </h6>
      )}
      {pretitle && direction === 'next' && (
        <h6>
          {tr.next}
          <span className={styles.pretitle}>{pretitle}</span> »
        </h6>
      )}
      <h4>
        <LineLink
          content={{ to: pageLink, text: title, linkType: 'pageLink' }}
        />
      </h4>
    </div>
  )
}
