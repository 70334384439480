import React from 'react'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { pretitle, pretitleLink, title, image, body } = content
  return (
    <div className='block pad-v-standard first-add-v-pad'>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          {pretitle && pretitleLink && (
            <h5>
              <LinkWrap to={pretitleLink}>{pretitle}</LinkWrap>
            </h5>
          )}
          {pretitle && !pretitleLink && <h5>{pretitle}</h5>}
          <h1>{title}</h1>
        </Inview>
        <Inview className={`grid-12 ${styles.image} fade-in up`}>
          <ImageWrap image={image} />
        </Inview>
        {body && (
          <Inview className={`${styles.text} fade-in up`}>
            <div className={styles.body}>
              <MarkdownWrap body={body} />
            </div>
          </Inview>
        )}
      </div>
    </div>
  )
}

export default Block
